<template>
  <div id="Navigation" class="custom-navigation">
    <div class="mt-2 d-flex align-items-center row">
      <div class="fontsize-h4 col-auto flex-shrink-0" v-if="displayTitle">
        {{ displayTitle }}
      </div>
      <div class="font-subtitle col-auto">
        <span v-for="(data, i) in itemsShow" :key="i">
          <template v-if="data.iconClass">
            <img class="mr-1" :src="data.iconClass" />
          </template>
          <template v-else>
            <b-icon icon="dot" scale="2"></b-icon>
          </template>

          {{ data.title }}
        </span>

        <span v-if="isCustom">
          <template v-if="itemsLast.iconClass">
            <img class="mr-1" :src="itemsLast.iconClass" />
          </template>
          <template v-else>
            <b-icon icon="dot" scale="2"></b-icon>
          </template>
          {{ displayTitle }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "core-navigation",
  props: {
    title: {
      type: String,
      default: null,
    },
    navList: {
      type: [Array, null],
      default: null,
    },
    isCustom: {
      type: [Boolean, null],
      default: false,
    },
  },
  data() {
    return {
      breadcrumbs: [],
      currentRouteName: null,
      items: [],
    };
  },
  created() {
    this.currentRouteName = this.$route.name;
    // let _menu = this.$utils.main.getPermissionMenu();
    this.breadcrumbs = this.$store.state.menus.breadcrumbs;
  },
  async mounted() {
    await this.setItems();
  },
  methods: {
    displayedCrumbs() {
      const findChilden = this.findChilden(
        this.currentRouteName,
        this.breadcrumbs
      );

      if (!findChilden.length) {
        // eslint-disable-next-line no-console
        console.warn(
          `No breadcrumbs registered for route with name "${this.currentRouteName}"`
        );
      }

      return findChilden;
    },
    async setItems() {
      const crumbs = this.displayedCrumbs();

      this.items = crumbs.map((crumb, key) => {
        return {
          title: crumb.title,
          // title:
          //   this.isCustom && key == crumbs.length - 1
          //     ? this.title
          //     : crumb.title,
          to: !crumb.to ? { name: crumb.to } : {},
          iconClass: crumb.iconClass,
        };
      });
    },
    findChilden(name, childen) {
      for (const item of childen) {
        // We found breadcrumbs for route
        if (item.to === name) {
          return [item];
        }
        // We didn't find any breadcrumbs for route - means we have to go deeper!
        // Which works only if route breadcrumb has children declared.
        if (!item.children) {
          continue;
        }

        let result = this.findChilden(name, item.children);
        if (result.length) {
          return [item, ...result];
        }
      }

      return [];
    },
  },
  computed: {
    displayTitle() {
      if (this.isCustom) {
        return this.title;
      } else {
        return this.items.slice(-1)[0]?.title || this.title;
      }
    },
    itemsLast() {
      return this.items.slice(-1)[0] || {};
    },
    itemsShow() {
      if (this.isCustom) {
        return this.items.slice(0, -1) || [];
      } else {
        return this.items || [];
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.custom-navigation {
  img {
    height: 23px;
  }

  .font-subtitle {
    span:not(:last-child) {
      margin-right: 15px;
    }
  }
}
</style>
