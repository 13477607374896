import { downloadFileExport } from "@/utils/file-utils";
import httpClient from "../httpClient";

const END_POINT = "scholarship";

class ScholarshipService {
  async getScholarship(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/findScholarship`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getById(id) {
    const params = {
      id: id,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/getById`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async postAddScholarship(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/addScholarship`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || {};
    }
  }

  async postEditScholarship(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/editScholarship`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || {};
    }
  }

  async postDeleteScholarship(id) {
    const params = {
      id: id,
    };
    const config = {
      method: "post",
      url: `${END_POINT}/deleteScholarship`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || {};
    }
  }

  async exportListScholarship(params) {
    downloadFileExport(`${END_POINT}/exportListScholarship`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListScholarship`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // return response || {};
  }
}

export default new ScholarshipService();
