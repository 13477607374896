import httpClient from "../httpClient";
import { downloadFileExport } from "@/utils/file-utils";

const END_POINT = "files";

class FileService {
  async uploadFiles(files) {
    let formData = new FormData();

    if (files && files.length > 0) {
      files.forEach((element) => {
        formData.append(`file`, element);
      });
    }

    const config = {
      method: "post",
      url: `${END_POINT}/uploadFile`,
      data: formData,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getDetailFile(ids) {
    const data = { id: [...ids] };
    const config = {
      method: "post",
      url: `${END_POINT}/getDetailFile`,
      data: data,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getFile(id) {
    const config = {
      method: "get",
      url: `${END_POINT}/getFile/${id}`,
    };

    const response = await httpClient(config);
    return response || {};
  }

  async downloadFile(ids) {
    const data = { id: [...ids] };
    const config = {
      method: "post",
      url: `${END_POINT}/downloadFile`,
      data: data,
      // headers: {
      //   "Access-Control-Expose-Headers": "Content-Disposition",
      // },
      responseType: "blob",
    };

    const response = await httpClient(config);

    return response || undefined;
  }

  async deleteFile(ids) {
    const data = { id: [...ids] };
    const config = {
      method: "post",
      url: `${END_POINT}/deleteFile`,
      data: data,
    };

    const response = await httpClient(config);

    return response || {};

    //ตัวอย่าง response
    //   {
    //     "status": true,
    //     "message": "delete success",
    //     "data": "",
    //     "total": 0,
    //     "latestUpdateDate": null,
    //     "dataFile": {
    //         "id": [
    //             198,
    //             199
    //         ]
    //     }
    // }

    //ตัวอย่าง response กรณีไม่มีไฟล์ให้ลบ
    //   {
    //     "status": true,
    //     "message": "delete success",
    //     "data": "",
    //     "total": 0,
    //     "latestUpdateDate": null,
    //     "dataFile": {
    //         "id": []
    //     }
    // }
  }

  async generateForm(params) {
    downloadFileExport(`${END_POINT}/generateForm`, params);

    return { status: true };

    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/generateForm`,
    //   params: params,
    // };

    // const response = await httpClient(config);

    // return response || {};
  }
}

export default new FileService();
