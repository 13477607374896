<template>
  <aside id="AdminSidebar" class="admin-sidebar shadow-sm">
    <perfect-scrollbar class="sidebar-perfect-scrollbar">
      <nav>
        <ul>
          <template v-for="(menu, index) in menus">
            <!--  parent-level , cannot click -->
            <template v-if="menu.parentId == 0 && menu.hasSub">
              <li
                class="li-menu parent-level"
                :key="index"
                :class="{
                  'active-submenu': menu.active || menu.expand,
                  active: menu.active || menu.expand,
                }"
              >
                <div class="menu-name-content">
                  <!-- <font-awesome-icon
                    class="nav-icon"
                    v-if="menu.iconClass"
                    :icon="menu.iconClass"
                  /> -->
                  <img
                    class="mr-3"
                    v-if="menu.iconClass"
                    :src="menu.iconClass"
                  />
                  <span>{{ menu.title }}</span>
                  <span class="title-collapse">{{ menu.titleCollapse }}</span>
                </div>
              </li>
            </template>
            <!--  parent-level , can click -->
            <template v-else-if="menu.parentId == 0">
              <li
                class="li-menu parent-level-click"
                :key="index"
                :class="{
                  'active-submenu': menu.active || menu.expand,
                  active: menu.active || menu.expand,
                }"
              >
                <!-- @click="setMenuActive(menu)" -->
                <router-link class="w-100" :to="{ name: menu.to }">
                  <div class="menu-name-content">
                    <!-- <font-awesome-icon
                      class="nav-icon"
                      v-if="menu.iconClass"
                      :icon="menu.iconClass"
                    /> -->
                    <img
                      class="mr-3"
                      v-if="menu.iconClass"
                      :src="menu.iconClass"
                    />
                    <span class="">{{ menu.title }}</span>
                    <span class="title-collapse">{{ menu.titleCollapse }}</span>
                  </div>
                </router-link>
              </li>
            </template>
            <!-- have children -->
            <template v-else>
              <li
                class="li-menu sub-level"
                :key="index"
                :class="{
                  'active-submenu': menu.active || menu.expand,
                  active: menu.active || menu.expand,
                }"
              >
                <router-link class="w-100" :to="{ name: menu.to }">
                  <div class="menu-name-content">
                    <!-- <font-awesome-icon
                      class="nav-icon"
                      v-if="menu.iconClass"
                      :icon="menu.iconClass"
                    /> -->
                    <img
                      class="mr-3"
                      v-if="menu.iconClass"
                      :src="menu.iconClass"
                    />
                    <span class="pl-4"
                      ><font-awesome-icon
                        class="mr-2 align-middle"
                        icon="fa-solid fa-circle"
                        style="font-size: 5px"
                      />{{ menu.title }}</span
                    >
                    <span class="title-collapse">{{ menu.titleCollapse }}</span>
                  </div>
                </router-link>
              </li>
            </template>
          </template>
        </ul>
      </nav>
      <div class="menu-controller" @click="sidemenuCollapse(false)"></div>
    </perfect-scrollbar>
    <div class="sidemenu-toggle-collapse" @click="sidemenuCollapse(true)">
      <font-awesome-icon class="m-auto" :icon="['fas', 'angle-double-left']" />
    </div>
  </aside>
</template>

<script>
import Header from "./Header.vue";
export default {
  components: { Header },
  name: "admin-sidebar",
  data() {
    return {
      menus: [],
    };
  },
  async created() {
    window._sidebar = this;

    let _menu = this.$utils.main.getPermissionMenu();

    this.menus = _menu
      .map((m) => {
        return {
          ...m,
          active: false,
          expand: false,
        };
      })
      .filter((f) => f.showMenu)
      .sort(function (a, b) {
        return parseFloat(a._order) - parseFloat(b._order);
      });

    this.$nextTick(() => {
      this.matchMenuSide(this.$route);
    });
  },
  methods: {
    sidemenuCollapse(value) {
      this.$emit("toggle-menu-func", value);
    },

    setMenuActive(menu, isSubMenu) {
      this.menus.map((f) => {
        if (menu.menuId !== f.menuId) {
          f.active = false;
        } else {
          f.active = !f.active;
        }
        return { ...f };
      });
    },
    matchMenuSide(route) {
      const _name = route.name;
      this.menus.map((f) => {
        f.active = false;
        f.expand = false;

        if (f.to === _name) {
          f.active = true;
          f.expand = true;
        } else if (f.to !== undefined && route.meta?.rootMenu === f.to) {
          f.active = true;
          f.expand = true;
        }
        return { ...f };
      });
    },
  },
  watch: {
    $route: {
      handler: function (route) {
        this.matchMenuSide(route);
      },
      deep: true,
    },
  },
};
</script>
