import moment from "moment";

const objRemoveNull = (object) => {
  Object.keys(object).forEach((key) => {
    if (object[key] === null) {
      delete object[key];
    }
  });

  return object;
};

const objRemoveAllError = (object) => {
  Object.keys(object).forEach((key) => {
    if (object[key] === null) {
      delete object[key];
    } else if (object[key] === undefined) {
      delete object[key];
    }
  });

  return object;
};

// console.log("File", m.file[0] instanceof File);
// console.log("Blob", m.file[0] instanceof Blob);
// console.log("Object", m.file[0] instanceof Object);
// console.log(
//   "Array",
//   m.file[0] instanceof Array,
//   m.file instanceof Array
// );

const checkIsTypeFile = (file) => {
  return file instanceof File;
};

const checkIsTypeBlob = (file) => {
  return file instanceof Blob;
};

const checkIsTypeArray = (file) => {
  return file instanceof Array;
};

const objToFormData = (object, mainstr = null, form_data) => {
  object = objRemoveNull(object);

  for (var key in object) {
    form_data.append(`${mainstr ? mainstr + "." : ""}${key}`, object[key]);
  }

  return form_data;
};

const objToFormDataFile = (array, form_data, mainstr = null) => {
  // object = objRemoveNull(array);
  array.forEach((element, i) => {
    element = objRemoveNull(element);
    // const keyDoc = `${mainstr}[${i}]`;
    const keyDoc = `${mainstr}`;
    form_data.append(`${keyDoc}`, element);
  });

  return form_data;
};

const logFormData = (formData) => {
  console.log("startttt");
  for (const pair of formData.entries()) {
    console.log(pair[0], ",", pair[1]);
  }
  console.log("doneeee");
};

const toFixed = (number, limit) => {
  if (number !== null && number !== "" && number !== undefined) {
    return Number(number).toFixed(limit);
  }
  return number;
};

const toCommas = (number, limit) => {
  if (
    number !== null &&
    number !== "" &&
    number !== undefined &&
    typeof number === "number"
  ) {
    // .toLocaleString()
    return Number(number)
      .toFixed(limit)
      .toString()
      .replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  }
  return number;
  // if(number!==null && number!=='' && number!==undefined){
  //   return  Number(number).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // }
  // return '0';
};

const getYearBudgetSelected = (date = null) => {
  try {
    let dateVal = date || moment();

    const todayYear = dateVal.year();
    const todayMonth = dateVal.month() + 1;

    if (todayMonth >= 10) {
      return todayYear + 1;
    }

    return todayYear;
  } catch (e) {
    return moment().year();
  }
};

const getYearBudgetText = (date = null) => {
  try {
    let dateVal = date || moment();

    const todayYear = dateVal.year();
    const todayMonth = dateVal.month() + 1;

    if (todayMonth >= 10) {
      return todayYear + 1;
    }

    return todayYear.add(543, "year").locale("th").format("YYYY");
  } catch (e) {
    return moment().add(543, "year").locale("th").format("YYYY");
  }
};

const getRangeDateByYearBudget = (year = null) => {
  try {
    let yearVal = year || moment().year();

    const result = {
      start: `${yearVal - 1}-10-01`,
      end: `${yearVal}-09-30`,
    };
    return { ...result };
  } catch (e) {
    return undefined;
  }
};

const getRangeDateByYearBudgetText = (year = null) => {
  try {
    let yearVal = year || moment().year();

    const startText = `${yearVal - 1}-10-01`;
    const endText = `${yearVal}-09-30`;

    const result = {
      startText: moment(startText)
        .add(543, "year")
        .locale("th")
        .format("D MMMM YYYY"),
      endText: moment(endText)
        .add(543, "year")
        .locale("th")
        .format("D MMMM YYYY"),
    };
    return { ...result };
  } catch (e) {
    return undefined;
  }
};

const JsonToString = (json = null) => {
  try {
    if (json) {
      return JSON.stringify(json);
    } else {
      return json;
    }
  } catch (e) {
    return undefined;
  }
};

const StringToJson = (data = "") => {
  try {
    if (data) {
      return JSON.parse(data);
    } else {
      return data;
    }
  } catch (e) {
    return undefined;
  }
};

const checkFileSize = (file, size = null) => {
  let isAllow = null;

  if (!size) {
    size = 5242880;
  }

  //default size => 5242880 = 5MB
  if (file.size > size) {
    isAllow = false;
  } else {
    isAllow = true;
  }

  return isAllow;
};

const formatBytes = (a, b = 2) => {
  if (0 === a) return "0 Bytes";
  const c = 0 > b ? 0 : b,
    d = Math.floor(Math.log(a) / Math.log(1024));
  return (
    parseFloat((a / Math.pow(1024, d)).toFixed(c)) +
    " " +
    ["Bytes", "KB", "MB", "GB", "TB", "PB", "EB", "ZB", "YB"][d]
  );
};

const isTypeFileImage = (file = []) => {
  const _typeFile = ["image/jpeg", "image/jpg", "image/png"];

  file.forEach((e) => {
    let checkType = !_typeFile.includes(e.type); //T ไม่รองรับ
    if (checkType) {
      return false;
    }
  });

  return true;
};

const isTypeFileAllowed = (file = [], typeFile = []) => {
  const _typeFile =
    typeFile !== null && typeFile.length > 0
      ? typeFile
      : [
          "application/pdf",
          "image/jpeg",
          "image/jpg",
          "image/png",
          // 'application/xls',
          // 'application/xlsx',
          // 'application/docx',
          // "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
          // "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
        ];

  file.forEach((e) => {
    let checkType = !_typeFile.includes(e.type); //T ไม่รองรับ
    if (checkType) {
      return false;
    }
  });

  return true;
};

const toTimeTH = (date) => {
  if (date !== null) {
    const _date = splitDateTime(date);
    return moment(_date.time).format("HH:mm") + " น.";
  } else {
    return null;
  }
};

const toDateTH = (date) => {
  if (date !== null) {
    const _date = splitDateTime(date);
    return moment(_date.date)
      .add(543, "year")
      .locale("th")
      .format("D MMMM YYYY");
  } else {
    return null;
  }
};

const toYearTH = (year) => {
  if (year !== null) {
    return moment(String(year)).add(543, "year").locale("th").format("YYYY");
  } else {
    return null;
  }
};

const toDateTime = (date, time, isConcat) => {
  if (isConcat) {
    return `${date}T${time}Z`;
  }

  const _date = date ? moment(date).format("yyyy-MM-DD") : null;
  const _time = time ? "" : null;

  if (date !== null && time !== null) {
    return `${_date}T${_time}Z`;
  } else if (date !== null) {
    return `${_date}T00:00:00.000Z`;
  }
  return null;
};

const splitDateTime = (datetime) => {
  const result = {
    datetime: datetime,
    date: null,
    time: null,
    timeFull: null,
  };

  try {
    const arr = datetime.split("T");

    if (arr.length === 0) {
      return result;
    }

    result["date"] = arr[0];

    if (arr.length > 1) {
      let timeFull = arr[1].replace("Z", "").replace("z", "");

      result["time"] = timeFull.split(".")[0];
      result["timeFull"] = timeFull;
    }

    return result;
    //  {
    //   date: datetime.split("T")[0],
    //   time: time.split(".")[0],
    //   timeFull: time,
    // };
  } catch (err) {
    return result;
  }
};

const getYearBudget = () => {
  if (Number(moment().format("MM")) >= 10) {
    return moment().add(1, "years").format("yyyy");
  } else {
    return moment().format("yyyy");
  }
};

const objToQueryStr = (obj) => {
  if (!obj) {
    return obj;
  }

  return Object.keys(obj)
    .filter((key) => obj[key])
    .map((key) => key + "=" + obj[key])
    .join("&");
};

const filterInvalidDate = (date, defaultVal) => {
  const _dateChecked = moment(date).year();
  if (_dateChecked < 2000) {
    return defaultVal || null; // moment().format("yyyy-MM-DD");
  } else {
    return date;
  }
};

export default {
  toFixed,
  toCommas,
  getYearBudgetSelected,
  getYearBudgetText,
  getRangeDateByYearBudget,
  getRangeDateByYearBudgetText,
  JsonToString,
  StringToJson,
  objToFormData,
  objToFormDataFile,
  logFormData,
  checkFileSize,
  formatBytes,
  isTypeFileAllowed,
  isTypeFileImage,
  objRemoveNull,
  objRemoveAllError,
  checkIsTypeFile,
  toDateTH,
  toTimeTH,
  toYearTH,
  toDateTime,
  splitDateTime,
  getYearBudget,
  objToQueryStr,
  filterInvalidDate,
};

export {
  toFixed,
  toCommas,
  getYearBudgetSelected,
  getYearBudgetText,
  getRangeDateByYearBudget,
  getRangeDateByYearBudgetText,
  JsonToString,
  StringToJson,
  objToFormData,
  objToFormDataFile,
  logFormData,
  checkFileSize,
  formatBytes,
  isTypeFileAllowed,
  isTypeFileImage,
  objRemoveNull,
  objRemoveAllError,
  toDateTH,
  toTimeTH,
  toYearTH,
  toDateTime,
  splitDateTime,
  getYearBudget,
  objToQueryStr,
  filterInvalidDate,
};
