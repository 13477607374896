import httpClient from "../httpClient";
import { downloadFileExport } from "@/utils/file-utils";

const END_POINT = "decoration";

class DecorationService {
  async getListPositionLevel(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listPositionLevel`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getListPersonal(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listPersonal`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getFindById(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/find`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getListPunishment(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listPunishment`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async postAddDecorationPersonal(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/addDecorationPersonal`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async postEditDecorationPersonal(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/editDecorationPersonal`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getListResultDecoration(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listResultDecoration`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getExportExcel(params) {
    downloadFileExport(`${END_POINT}/exportExcel`, params);

    return { status: true };
  }

  async getExportResultPersonalReport(params) {
    downloadFileExport(`${END_POINT}/exportResultPersonalReport`, params);

    return { status: true };
  }

  async getExportResultSumReport(params) {
    downloadFileExport(`${END_POINT}/exportResultSumReport`, params);

    return { status: true };
  }
}

export default new DecorationService();
