import { downloadFileExport } from "@/utils/file-utils";
import httpClient from "../httpClient";
import { objToFormData, objToFormDataFile } from "@/utils/convert-utils";

const END_POINT = "decorationPersonal";

class DecorationPersonalService {
  async getSearchPersonalDecoration(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/searchPersonalDecoration`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getListEachYear(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listEachYear`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getPersonalDecorationById(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/personalDecorationById`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async postUpdatePersonalDecoration(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/updatePersonalDecoration`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async postDeletePersonalDecoration(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/deletePersonalDecoration`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getExportListDecorationEachYear(params) {
    downloadFileExport(`${END_POINT}/exportListDecorationEachYear`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListDecorationEachYear`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // if (response && response.data) {
    //   return response;
    // } else {
    //   return response || [];
    // }
  }

  async getExportListAmountDecoration(params) {
    downloadFileExport(`${END_POINT}/exportListAmountDecoration`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListAmountDecoration`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // if (response && response.data) {
    //   return response;
    // } else {
    //   return response || [];
    // }
  }

  async getExportListModal(params) {
    downloadFileExport(`${END_POINT}/exportListModal`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListModal`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // if (response && response.data) {
    //   return response;
    // } else {
    //   return response || [];
    // }
  }

  async getSearchHistory(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/searchHistory`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getByID(id) {
    const param = {
      id: id,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/getByID`,
      params: param,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async postUpdateByID(params) {
    let formData = new FormData();

    const { receiveFile, returnFile, ...rest } = params;

    objToFormData(params, "", formData);

    // if (receiveFile !== null) {
    // objToFormDataFile(receiveFile, formData, "receiveFile");
    // console.log(receiveFile);
    // objToFormData(receiveFile, "receiveFile", formData);
    // }

    // if (returnFile !== null) {
    //   objToFormDataFile(returnFile, formData, "returnFile");
    // }

    const config = {
      method: "post",
      url: `${END_POINT}/updateByID`,
      data: formData,
      // headers: {
      //   "Content-Type": "multipart/form-data",
      // },
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getExportHistory(params) {
    downloadFileExport(`${END_POINT}/exportHistory`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportHistory`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // if (response && response.data) {
    //   return response;
    // } else {
    //   return response || [];
    // }
  }
}

export default new DecorationPersonalService();
