import httpClient from "../httpClient";

const END_POINT = "selector";

class SelectorService {
  async getSearch(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/search`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }
}

export default new SelectorService();
