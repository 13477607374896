import { downloadFileExport } from "@/utils/file-utils";
import httpClient from "../httpClient";

const END_POINT = "trainingResult";

class TrainingResultService {
  async getTrainingResult(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/findTrainingResult`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getById(id) {
    const params = {
      id: id,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/getById`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async postAddTrainingResult(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/addTrainingResult`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async postEditTrainingResult(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/editTrainingResult`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async postDeleteTrainingResult(id) {
    const params = {
      id: id,
    };
    const config = {
      method: "post",
      url: `${END_POINT}/deleteTrainingResult`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getCertificate(id) {
    const params = {
      id: id,
    };

    downloadFileExport(`${END_POINT}/getTrainingResultCertificate`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/getTrainingResultCertificate`,
    //   params: params,
    // };

    // const response = await httpClient(config);

    // return response || {};
  }

  async approveTrainingResultStatus(data) {
    const config = {
      method: "post",
      url: `${END_POINT}/approveTrainingResultStatus`,
      data: data,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || {};
    }
  }

  async exportListTrainingReportResult(params) {
    downloadFileExport(`${END_POINT}/exportListTrainingReportResult`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListTrainingReportResult`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // return response || {};
  }
}

export default new TrainingResultService();
