import { objToFormData } from "@/utils/convert-utils";
import httpClient from "../httpClient";

const END_POINT = "welfareSetting";

class WelfareSettingService {
  async getSearch(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/search`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getFind(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/find`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async postEditWelfare(data) {
    let formData = new FormData();

    objToFormData(data, "", formData);

    const config = {
      method: "post",
      url: `${END_POINT}/editWelfareSetting`,
      data: formData,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async postUpdateEnable(data) {
    const config = {
      method: "post",
      url: `${END_POINT}/updateEnable`,
      params: data,
    };

    const response = await httpClient(config);

    return response || {};
  }
}

export default new WelfareSettingService();
