import httpClient from "../httpClient";
import month from "@/json/month";
const yearStart = 2017;
const END_POINT = "filter";

class FilterService {
  async getYearFilter() {
    try {
      const date = new Date();
      let thisYear = date.getFullYear() + 1;
      let _res = [];

      for (; thisYear >= yearStart - 1; thisYear--) {
        _res.push({
          id: thisYear,
          text: thisYear + 543,
        });
      }

      return _res;
    } catch (e) {
      return [];
    }
  }

  async getYearDecoFilter() {
    const yearStartDeco = 2022;
    try {
      const date = new Date();
      let thisYear = date.getFullYear() + 1;
      let _res = [];

      for (; thisYear >= yearStartDeco; thisYear--) {
        _res.push({
          id: thisYear,
          text: thisYear + 543,
        });
      }

      return _res;
    } catch (e) {
      return [];
    }
  }

  // async getYearBudgetFilter() {
  //   try {
  //     const date = new Date();
  //     let thisYear = date.getFullYear() + 1;
  //     let _res = [];

  //     for (; thisYear >= yearStart - 1; thisYear--) {
  //       _res.push({
  //         id: thisYear,
  //         text: thisYear + 543,
  //       });
  //     }

  //     return _res;
  //   } catch (e) {
  //     return [];
  //   }
  // }

  async getMonthFilter() {
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/monthFilter`,
    // };
    // const response = await httpClient(config);
    // if (response && response.data) {
    //   return response.data;
    // } else {
    //   return response || [];
    // }

    return month;
  }

  async getWelfareTypeFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/welfareTypeFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getOrganizeFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/organizeFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getPersonPrefixFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/personPrefixFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getPersonTypeFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/personTypeFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getPositionLevelFilter(positionTypeId) {
    const params = {
      positionTypeId: positionTypeId || null,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/positionLevelFilter`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getPositionTypeFilter(personTypeId) {
    const params = {
      personTypeId: personTypeId || null,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/positionTypeFilter`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getPositionLineFilter(positionTypeId) {
    const params = {
      positionTypeId: positionTypeId || null,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/positionLineFilter`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getPositionLineGroupFilter(positionLevelId) {
    const params = {
      positionLevelId: positionLevelId || null,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/positionLineGroup`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getRelationshipFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/relationshipFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getTrainingFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/trainingFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getDecorationFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/decorationFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getDecorationByTypeFilter(typeId) {
    const param = { decorationTypeId: typeId };
    const config = {
      method: "get",
      url: `${END_POINT}/decorationByTypeFilter`,
      params: param,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getDecorationTypeFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/decorationTypeFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getCountryFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/countryFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getDecorationReceiveTypeFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/decorationReceiveTypeFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getReturnResonFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/returnResonFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getPersonalStatus() {
    const data = [
      { id: 0, text: "เสียชีวิตแล้ว" },
      { id: 1, text: "มีชีวิตอยู่" },
    ];
    return [...data];
  }

  async getUserTypeFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/userTypeFilter`,
    };

    const type_temp = [
      { id: 1, text: "ระบบงานเครื่องราชอิสริยาภรณ์" },
      { id: 2, text: "ระบบงานพัฒนาบุคลากร" },
      { id: 3, text: "ระบบคำขอรับสวัสดิการ" },
      { id: 4, text: "ระบบจัดการผู้ใช้งาน" },
      { id: 5, text: "ผู้ใช้งานทั่วไป" },
    ];

    return type_temp;

    // const response = await httpClient(config);

    // if (response && response.data) {
    //   return response.data;
    // } else {
    //   return response || [];
    // }
  }

  async getTrainingFeatureFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/trainingFeatureFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getTrainingBudgetTypeFilter() {
    const config = {
      method: "get",
      url: `${END_POINT}/trainingBudgetTypeFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async getTrainingTypeActivityFilter() {
    // const type_temp = [
    //   { id: 1, text: "อบรม" },
    //   { id: 2, text: "ดูงาน" },
    //   { id: 3, text: "ประชุม" },
    //   { id: 4, text: "สัมมนา" },
    //   { id: 5, text: "ฟังการบรรยาย" },
    //   { id: 6, text: "ประชุมเชิงปฏิบัติการ" },
    //   { id: 7, text: "การพัฒนาทักษะด้านช่างเทคนิค" },
    //   { id: 8, text: "อื่นๆ" },
    // ];

    // return type_temp;

    const config = {
      method: "get",
      url: `${END_POINT}/trainingTypeActivityFilter`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || [];
    }
  }
}

export default new FilterService();
