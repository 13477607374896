import store from "@/store/index";
// import { StringToJson } from "./convert-utils";
import { TokenService } from "@/services/storage.service";

const permission = (to, auth) => {
  //const _menu = store.state.menus.menus;

  const _per = getPermissionMenu();

  //check menu with auth
  const result =
    _per.filter((f) => {
      return f.to === to || f.auth?.indexOf(auth) > -1;
    })[0] || {};

  return {
    showMenu: result.showMenu,
    isView: result.isView,
    isModified: result.isModified,
  };
};

const findMenuId = (list, _auth) => {
  let find = [];

  list.forEach((element) => {
    if (element.auth && element.auth.includes(_auth) > 0) {
      find.push(element);
      return;
    } else if (element.children && element.children.length > 0) {
      const _menu = findMenuId(element.children, _auth);
      if (_menu) {
        find.push(_menu);
        return;
      }
    }
  });

  if (find.length > 0) {
    return find[0];
  } else {
    return null;
  }
};

const getLanding = () => {
  const _menu = getPermissionMenu();
  return _menu.filter((f) => f.showMenu && f.parentId != 0)[0]?.to || null;
};

const getMenuAccess = () => {
  // const perm = store.state.account?.userInfo?.info?.menuAccess || "";
  // return StringToJson(perm) || [];
  return store.state.account?.userInfo.roleIds || [];
};

const getPermissionMenu = () => {
  const _menu = store.state.menus.menus;
  const _per = getMenuAccess();

  // if (_per) {
  //   return _menu.map((m) => {
  //     return {
  //       ...m,
  //       showMenu: true,
  //       isModified: true,
  //       isView: true,
  //     };
  //   });
  // }

  //compare _menu with menuAccedd for assign permission
  const menuAccess = _menu.map((m) => {
    if (m.parentId == 0) {
      const _access = _per.filter((ff) => ff === m.role).length > 0 || false;

      // //find sub menu
      // const _sub1 =
      //   _per.filter(
      //     (ff) => ff.pid === m.menuId && (ff.isView || ff.isModified)
      //   ) || [];
      // const _subAccess = _sub1.length;

      return {
        ...m,
        showMenu: _access, //_access?.isModified || _access?.isView || _subAccess > 0,
        isModified: _access, //_access?.isModified || false,
        isView: _access, //_access?.isView || false,
      };
    } else {
      const parentRole =
        _menu.find((ff) => ff.menuId === m.parentId)?.role || null;

      const _access =
        _per.filter((ff) => ff === parentRole).length > 0 || false;

      if (_access) {
        return {
          ...m,
          showMenu: _access, //_access?.isModified || _access?.isView,
          isModified: _access, // _access?.isModified || false,
          isView: _access, // _access?.isView || false,
        };
      } else {
        return {
          ...m,
          showMenu: false,
          isModified: false,
          isView: false,
        };
      }
    }
  });

  return menuAccess;
};

const getUserName = () => {
  return store.state.account?.userInfo?.fullName || null;
};

const getOrgName = () => {
  return store.state.account?.userInfo?.organizeName || null;
};

const getUserInfo = () => {
  return store.state.account?.userInfo || null;
};

const downloadUrl = (str) => {
  if (!str) {
    return process.env.VUE_APP_BASE_API_DOWNLOAD;
  } else {
    const arrStr = str.split("/");
    return `${process.env.VUE_APP_BASE_API_DOWNLOAD}/api/${arrStr.join("/")}`;
  }
};

const getLinkFileUrl = (id) => {
  return `${process.env.VUE_APP_BASE_API_DOWNLOAD}/api/files/getfile/${id}`;
};

const getLinkFileTemplateUrl = (id) => {
  return `${process.env.VUE_APP_BASE_API_DOWNLOAD}/api/files/getTemplateFile?id=${id}`;
};

const getAccessToken = () => {
  return TokenService.getToken() || "";
};

export default {
  permission,
  getLanding,
  getMenuAccess,
  getPermissionMenu,
  getUserName,
  getOrgName,
  downloadUrl,
  getUserInfo,
  getLinkFileUrl,
  getAccessToken,
};

export {
  getLinkFileUrl,
  getLinkFileTemplateUrl,
  downloadUrl,
  getAccessToken,
  getLanding,
  permission,
};
