import httpClient from "../httpClient";
import { downloadFileExport } from "@/utils/file-utils";

const END_POINT = "welfareForm";

import { objToFormData, objToFormDataFile } from "@/utils/convert-utils";
class WelfareFormService {
  async getSearch(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/search`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getFind(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/find`,
      params: params,
    };

    const response = await httpClient(config);

    return response.data || {};
  }

  async getExportExcel(params) {
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportExcel`,
    //   params: params,
    // };

    // const response = await httpClient(config);

    // return response || {};
    downloadFileExport(`${END_POINT}/exportExcel`, params);

    return { status: true };
  }

  async postAddWelfare(param, mainstr) {
    // console.log("in postAddWelfare : ",param,mainstr)

    let formData = new FormData();

    const {
      typeWelfareForm,
      formFile,
      // copyWelfareFormFile,
      ...rest
    } = param;

    objToFormData(typeWelfareForm, mainstr, formData);
    objToFormData(rest, "", formData);

    objToFormDataFile(formFile, formData, "formFile");
    // objToFormDataFile(copyWelfareFormFile,formData,"copyWelfareFormFile")

    const config = {
      method: "post",
      url: `${END_POINT}/addWelfareForm`,
      data: formData,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async postEditWelfare(param, mainstr) {
    console.log("in postEditWelfare : ", param, mainstr);

    let formData = new FormData();

    const {
      typeWelfareForm,
      formFile,
      copyWelfareFormFile,
      formFileId,
      ...rest
    } = param;

    objToFormData(typeWelfareForm, mainstr, formData);
    objToFormData(copyWelfareFormFile, "copyWelfareFormFile", formData);

    objToFormDataFile(formFile, formData, "formFile");

    // objToFormData(formFile, "formFile", formData);

    formFileId.forEach((data, i) => {
      objToFormData(data, `formFileId[${i}]`, formData);
    });

    objToFormData(rest, "", formData);

    // objToFormDataFile(formFile,formData,"formFile")

    const config = {
      method: "post",
      url: `${END_POINT}/editWelfareForm`,
      data: formData,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async postDeletetWelfare(data) {
    const config = {
      method: "post",
      url: `${END_POINT}/deleteWelfareForm`,
      params: data,
    };

    const response = await httpClient(config);

    return response || {};
  }
}

export default new WelfareFormService();
