import Vue from "vue";
import VueRouter from "vue-router";
import AppLogin from "@/components/layouts/login/LoginLayout";
import AppLayout from "@/components/layouts/app/AppLayout";
import store from "@/store/index";
import { TokenService } from "@/services/storage.service";
import { getLanding, permission } from "@/utils/main-utils";

Vue.use(VueRouter);

const EmptyParentComponent = {
  template: "<router-view></router-view>",
};

const routes = [
  {
    path: "",
    redirect: { name: "Login" },
  },
  {
    path: "*",
    redirect: { name: "Login" },
  },
  {
    path: "/",
    component: AppLogin,
    meta: {
      login: true,
    },
    children: [
      {
        name: "Login",
        path: "",
        component: () => import("../views/Auth/Login.vue"),
      },
    ],
  },
  {
    path: "/",
    component: AppLayout,
    meta: {
      public: true,
    },
    children: [
      {
        name: "Example",
        path: "example",
        component: () => import("../views/Example.vue"),
      },
    ],
  },
  {
    path: "/",
    component: AppLayout,
    children: [
      {
        path: "welfare",
        component: EmptyParentComponent,
        redirect: { name: "ImportWelfare" },
        children: [
          {
            path: "form",
            redirect: { name: "ImportWelfare" },
          },
          {
            name: "ImportWelfare",
            path: "list",
            component: () =>
              import("../views/Management/ImportWelfare/List.vue"),
          },
          {
            meta: {
              rootMenu: "ImportWelfare",
            },
            name: "FormWelfare",
            path: "form/:mode",
            component: () =>
              import("../views/Management/ImportWelfare/Form.vue"),
          },
          {
            name: "ReportWelfare",
            path: "report",
            component: () =>
              import("../views/Management/ReportWelfare/List.vue"),
          },
          {
            name: "SettingWelfare",
            path: "setting",
            component: () =>
              import("../views/Management/SettingWelfare/List.vue"),
          },
        ],
      },
      {
        path: "training-course",
        component: EmptyParentComponent,
        redirect: { name: "CourseList" },
        children: [
          {
            path: "form",
            redirect: { name: "CourseList" },
          },
          {
            name: "CourseList",
            path: "list",
            component: () => import("../views/Development/Course/List.vue"),
          },
          {
            meta: {
              rootMenu: "CourseList",
            },
            name: "CourseForm",
            path: "form/:mode",
            component: () => import("../views/Development/Course/Form.vue"),
            props: (route) => ({ mode: route.params.mode }),
            // beforeEnter: (to, from, next) => {
            //   function isValid (param) {
            //      // check if param is valid
            //   }

            //    if (!isValid(to.params.slug)) {
            //      next({ name: 'Notfound' });
            //    }

            //    next();
            //  }
          },
        ],
      },
      {
        path: "training-manage",
        component: EmptyParentComponent,
        redirect: { name: "ReportTrainingManage" },
        children: [
          {
            name: "ReportTrainingManage",
            path: "list",
            component: () =>
              import("../views/Development/ListAndReport/List.vue"),
          },
          {
            meta: {
              rootMenu: "ReportTrainingManage",
            },
            name: "ReportTrainingDetail",
            path: "detail",
            component: () =>
              import(
                "../views/Development/ListAndReport/ReportTrainingDetail.vue"
              ),
          },
          {
            meta: {
              rootMenu: "ReportTrainingManage",
            },
            name: "TrainingRegisForm",
            path: "detail/:mode",
            component: () =>
              import(
                "../views/Development/ListAndReport/TrainingRegisForm.vue"
              ),
          },
        ],
      },
      {
        path: "scholarship",
        component: EmptyParentComponent,
        redirect: { name: "ScholarshipList" },
        children: [
          {
            path: "form",
            redirect: { name: "ScholarshipList" },
          },
          {
            name: "ScholarshipList",
            path: "list",
            component: () =>
              import("../views/Development/ScholarshipInfo/List.vue"),
          },
          {
            meta: {
              rootMenu: "ScholarshipList",
            },
            name: "ScholarshipForm",
            path: "form/:mode",
            component: () =>
              import("../views/Development/ScholarshipInfo/Form.vue"),
            props: (route) => ({ mode: route.params.mode }),
          },
        ],
      },
      {
        name: "ReportDevelopment",
        path: "training-report",
        component: () =>
          import("../views/Development/ReportDevelopment/List.vue"),
      },
      {
        path: "insignia",
        component: EmptyParentComponent,
        // redirect: { name: "InsListPosition" },
        children: [
          {
            path: "listPosition",
            component: EmptyParentComponent,
            redirect: { name: "InsListPosition" },
            children: [
              {
                name: "InsListPosition",
                path: "",
                component: () =>
                  import("../views/Insignia/PersonList/PositionList.vue"),
              },
              {
                meta: {
                  rootMenu: "InsListPosition",
                },
                name: "InsListPositionPerson",
                path: "person",
                component: () =>
                  import("../views/Insignia/PersonList/List.vue"),
              },

              {
                meta: {
                  rootMenu: "InsListPosition",
                },
                name: "InsPersonAdd",
                path: "person/add",
                component: () =>
                  import("../views/Insignia/PersonList/FormAdd.vue"),
              },
              {
                path: "person/manage",
                redirect: { name: "InsListPositionPerson" },
              },
              {
                meta: {
                  rootMenu: "InsListPosition",
                },
                name: "InsPersonalManage",
                path: "person/manage/:mode",
                component: () =>
                  import("../views/Insignia/PersonList/FormManage.vue"),
              },
              {
                meta: {
                  rootMenu: "InsListPosition",
                },
                name: "InsPersonalSummary",
                path: "person/summary",
                component: () =>
                  import("../views/Insignia/PersonList/ResultsList.vue"),
              },
            ],
          },
          {
            path: "managePerson",
            component: EmptyParentComponent,
            redirect: { name: "InsManagePerson" },
            children: [
              {
                name: "InsManagePerson",
                path: "list",
                component: () =>
                  import("../views/Insignia/TotalInsigniaPerson/TotalList.vue"),
              },
              {
                meta: {
                  rootMenu: "InsManagePerson",
                },
                name: "InsManagePersonList",
                path: "list/decoration",
                component: () =>
                  import(
                    "../views/Insignia/TotalInsigniaPerson/TotalManageList.vue"
                  ),
              },
              {
                path: "decoration",
                redirect: { name: "InsManagePersonList" },
              },
              {
                meta: {
                  rootMenu: "InsManagePerson",
                },
                name: "InsManagePersonForm",
                path: "decoration/manage",
                component: () =>
                  import(
                    "../views/Insignia/TotalInsigniaPerson/TotalManageForm.vue"
                  ),
              },
            ],
          },
          {
            path: "historyPerson",
            component: EmptyParentComponent,
            redirect: { name: "InsHistoryPerson" },
            children: [
              {
                name: "InsHistoryPerson",
                path: "list",
                component: () =>
                  import(
                    "../views/Insignia/ReceiveReturnPerson/HistoryReceiveReturn.vue"
                  ),
              },
              {
                meta: {
                  rootMenu: "InsHistoryPerson",
                },
                name: "ManageForm1",
                path: "manageForm1",
                component: () =>
                  import(
                    "../views/Insignia/ReceiveReturnPerson/ManageForm1.vue"
                  ),
              },
              {
                meta: {
                  rootMenu: "InsHistoryPerson",
                },
                name: "ManageForm2",
                path: "manageForm2",
                component: () =>
                  import(
                    "../views/Insignia/ReceiveReturnPerson/ManageForm2.vue"
                  ),
              },
              {
                meta: {
                  rootMenu: "InsHistoryPerson",
                },
                name: "ManageForm3",
                path: "manageForm3",
                component: () =>
                  import(
                    "../views/Insignia/ReceiveReturnPerson/ManageForm3.vue"
                  ),
              },
            ],
          },
          {
            path: "manageOfficer",
            component: EmptyParentComponent,
            redirect: { name: "InsManageOfficer" },
            children: [
              {
                name: "InsManageOfficer",
                path: "list",
                component: () =>
                  import(
                    "../views/Insignia/TotalInsigniaCentral/TotalList.vue"
                  ),
              },
              {
                meta: {
                  rootMenu: "InsManageOfficer",
                },
                name: "InsManageOfficerDecoration",
                path: "list/decoration",
                component: () =>
                  import(
                    "../views/Insignia/TotalInsigniaCentral/TotalManageList.vue"
                  ),
              },
            ],
          },
          {
            path: "historyOfficer",
            component: EmptyParentComponent,
            redirect: { name: "InsHistoryOfficer" },
            children: [
              {
                name: "InsHistoryOfficer",
                path: "list",
                component: () =>
                  import(
                    "../views/Insignia/BorrowReturnCentral/HistoryBorrowReturn.vue"
                  ),
              },
              {
                path: "manage",
                redirect: { name: "InsHistoryOfficer" },
              },
              {
                meta: {
                  rootMenu: "InsHistoryOfficer",
                },
                name: "InsHistoryOfficerForm",
                path: "manage/:mode",
                component: () =>
                  import("../views/Insignia/BorrowReturnCentral/FormAdd.vue"),
              },
              {
                meta: {
                  rootMenu: "InsHistoryOfficer",
                },
                name: "InsHistoryOfficerManage",
                path: "manage1",
                component: () =>
                  import(
                    "../views/Insignia/BorrowReturnCentral/ManageForm1.vue"
                  ),
              },
              {
                meta: {
                  rootMenu: "InsHistoryOfficer",
                },
                name: "InsHistoryOfficerManage2",
                path: "manage2",
                component: () =>
                  import(
                    "../views/Insignia/BorrowReturnCentral/ManageForm2.vue"
                  ),
              },
              {
                meta: {
                  rootMenu: "InsHistoryOfficer",
                },
                name: "InsHistoryOfficerManage3",
                path: "manage3",
                component: () =>
                  import(
                    "../views/Insignia/BorrowReturnCentral/ManageForm3.vue"
                  ),
              },
              {
                meta: {
                  rootMenu: "InsHistoryOfficer",
                },
                name: "InsHistoryOfficerManage4",
                path: "manage4",
                component: () =>
                  import(
                    "../views/Insignia/BorrowReturnCentral/ManageForm4.vue"
                  ),
              },
              {
                meta: {
                  rootMenu: "InsHistoryOfficer",
                },
                name: "InsHistoryOfficerManage5",
                path: "manage5",
                component: () =>
                  import(
                    "../views/Insignia/BorrowReturnCentral/ManageForm5.vue"
                  ),
              },
            ],
          },
        ],
      },
      {
        path: "user",
        component: EmptyParentComponent,
        redirect: { name: "UserList" },
        children: [
          {
            path: "form",
            redirect: { name: "UserList" },
          },
          {
            name: "UserList",
            path: "list",
            component: () => import("../views/Users/UserList/List.vue"),
          },
          {
            meta: {
              rootMenu: "UserList",
            },
            name: "UserListForm",
            path: "form/:mode",
            component: () => import("../views/Users/UserList/Form.vue"),
          },
          {
            name: "UserHistory",
            path: "usage-history",
            component: () => import("../views/Users/History/List.vue"),
          },
        ],
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit("setPageLoading", true);

  let changeNext;

  const haveToken = !!TokenService.getToken();
  const toMatchedLogin = to.matched.some((record) => record.meta.login);

  if (
    (toMatchedLogin && !haveToken) ||
    to.matched.some((record) => record.meta.public)
  ) {
  } else if (!haveToken) {
    return next({ name: "Login" });
  } else if (toMatchedLogin && haveToken) {
    const to = getLanding();
    if (to) {
      changeNext = to;
    }
  } else {
    //check menu permission
    const _permission = permission(to.meta?.rootMenu || to.name);
    if (!_permission.showMenu) {
      const to = getLanding();
      if (to) {
        changeNext = to;
      } else {
        //clear token, vuex account
        TokenService.removeToken();
        TokenService.removeRefreshToken();
        store.commit("account/logoutSuccess");

        return next({ name: "Login" });
      }
    }
  }

  if (changeNext) {
    if (from.name === changeNext) {
      // hide loading page if from and next is same route
      store.commit("setPageLoading", false);
    }
    changeNext = { name: changeNext };
  }

  return next(changeNext);
});

router.afterEach(async (to, from, next) => {
  store.commit("setPageLoading", false);
});

const VueRouterPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(to) {
  return VueRouterPush.call(this, to).catch((err) => err);
};

const VueRouterReplace = VueRouter.prototype.replace;
VueRouter.prototype.replace = function replace(to) {
  return VueRouterReplace.call(this, to).catch((err) => err);
};

export default router;
