import { downloadFileExport } from "@/utils/file-utils";
import httpClient from "../httpClient";

const END_POINT = "trainingRegistration";

class TrainingRegistrationService {
  async getList(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/findTrainingRegistration`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getById(regisId) {
    const params = {
      id: regisId,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/getById`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async getSummaryRegistrationResult(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/getSummaryRegistrationResult`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async postAddTrainingRegis(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/addTrainingRegistration`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async postEditTrainingRegis(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/editTrainingRegistration`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async postDeleteTrainingRegis(regisId) {
    const params = {
      id: regisId,
    };
    const config = {
      method: "post",
      url: `${END_POINT}/deleteTrainingRegistration`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async approveRegistrationStatus(trainingId, data) {
    const config = {
      method: "post",
      url: `${END_POINT}/approveRegistrationStatus`,
      params: {
        trainingId: trainingId,
      },
      data: data,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async exportListTrainingRegister(params) {
    downloadFileExport(`${END_POINT}/exportListTrainingRegister`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListTrainingRegister`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // return response || {};
  }

  async exportListTrainingJoin(params) {
    downloadFileExport(`${END_POINT}/exportListTrainingJoin`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListTrainingJoin`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // return response || {};
  }
}

export default new TrainingRegistrationService();
