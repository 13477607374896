<template>
  <div id="AdminFooter" class="text-center admin-footer">
    Copyright By Piesoft Co.,Ltd. | Version 1.0.1
  </div>
</template>

<script>
export default {
  name: "admin-footer",
};
</script>

<style></style>
