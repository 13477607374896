import httpClient from "../httpClient";
import httpClientPublic from "../httpClientPublic";
import { downloadFileExport } from "@/utils/file-utils";

import { objToFormData, objToFormDataFile } from "@/utils/convert-utils";

const END_POINT = "decorationOrganize";

class DecorationOrganizeService {
  async getSearchOrganizeDecoration(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/searchOrganizeDecoration`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }
  async getExportListAmountOrgDecoration(params) {
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListAmountOrgDecoration`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // if (response && response.data) {
    //   return response;
    // } else {
    //   return response || [];
    // }
    downloadFileExport(`${END_POINT}/exportListAmountOrgDecoration`, params);

    return { status: true };
  }
  async getListEachYear(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/listEachYear`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }
  async getExportListEachYear(params) {
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListEachYear`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // if (response && response.data) {
    //   return response;
    // } else {
    //   return response || [];
    // }
    downloadFileExport(`${END_POINT}/exportListEachYear`, params);

    return { status: true };
  }
  async postAddOrganize(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/addOrganize`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }
  async getByIdOrganize(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/getByIdOrganize`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }
  async postDeleteOrganize(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/deleteOrganize`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getCardHistory() {
    const config = {
      method: "get",
      url: `${END_POINT}/cardHistory`,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getSearchHistory(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/searchHistory`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getExportHistory(params) {
    downloadFileExport(`${END_POINT}/exportHistory`, params);

    return { status: true };
  }

  async getHistoryByID(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/getHistoryByID`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async postAddHistory(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/addHistory`,
      data: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async postUpdateHistory(params) {
    let formData = new FormData();
    const { file, ...rest } = params;

    objToFormData(rest, "", formData);

    const config = {
      method: "post",
      url: `${END_POINT}/updateHistory`,
      data: formData,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async postDeleteHistory(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/deleteHistory`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }
}

export default new DecorationOrganizeService();
