import navigation from "./navigation";
import datepicker from "./datepicker";
import customTable from "./customTable";
import multiselectOrg from "./multiselectOrg";

const ComponentPlugin = {
  install(Vue, options) {
    [navigation, datepicker, customTable, multiselectOrg].forEach(
      (component) => {
        Vue.component(component.name, component);
      }
    );
  },
};

export default ComponentPlugin;
