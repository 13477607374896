<template>
  <b-modal
    id="HelperSeletorPerson"
    ref="my-helper-person"
    size="xl"
    scrollable
    hide-footer
    centered
    no-close-on-backdrop
  >
    <template #modal-title>
      <div class="font-white">ตัวช่วยเลือกบุคลากร</div>
    </template>
    <div>
      <div class="row">
        <div class="col-lg-4 col-md-12">
          <div class="form-group">
            <label for="" class="col-form-label-verticle">สังกัด</label>
            <div class="w-100">
              <multiselect-org
                v-model="orgarnizeSelected"
                :options="orgarnizeOptions"
              ></multiselect-org>
              <!-- <multiselect
                v-model="orgarnizeSelected"
                :options="orgarnizeOptions"
              >
                <template slot="option" slot-scope="props">
                  <div class="org-options">
                    <small class="org-detail">{{ props.option.detail }}</small>
                    <div class="org-text">{{ props.option.text }}</div>
                  </div>
                </template>
                <span slot="noResult">ไม่มีข้อมูล</span>
                <span slot="noOptions">ไม่มีข้อมูล</span>
              </multiselect> -->
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="form-group">
            <label for="" class="col-form-label-verticle">ชื่อ-นามสกุล</label>
            <div class="w-100">
              <input type="text" class="form-control" v-model="personName" />
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="form-group">
            <label for="" class="col-form-label-verticle">ประเภทบุคลากร</label>
            <div class="w-100">
              <multiselect
                v-model="personalTypeSelected"
                :options="personalTypeOptions"
                @select="changePersonType"
                :disabled="personalTypeDisabled"
              >
                <span slot="noResult">ไม่มีข้อมูล</span>
                <span slot="noOptions">ไม่มีข้อมูล</span>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="row mt-2">
        <div class="col-lg-4 col-md-12">
          <div class="form-group">
            <label for="" class="col-form-label-verticle">ประเภทตำแหน่ง</label>
            <div class="w-100">
              <multiselect
                v-model="positionTypeSelected"
                :options="positionTypeOptions"
                @select="changePositionType"
                :disabled="positionTypeDisabled"
              >
                <span slot="noResult">ไม่มีข้อมูล</span>
                <span slot="noOptions">ไม่มีข้อมูล</span>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="form-group">
            <label for="" class="col-form-label-verticle">ระดับตำแหน่ง </label>
            <div class="w-100">
              <multiselect
                v-model="positionLevelSelected"
                :options="positionLevelOptions"
                :disabled="positionLevelDisabled"
              >
                <span slot="noResult">ไม่มีข้อมูล</span>
                <span slot="noOptions">ไม่มีข้อมูล</span>
              </multiselect>
            </div>
          </div>
        </div>
        <div class="col-lg-4 col-md-12">
          <div class="form-group">
            <label for="" class="col-form-label-verticle"
              >ตำแหน่งในสายงาน
            </label>
            <div class="w-100">
              <multiselect
                v-model="positionLineSelected"
                :options="positionLineOptions"
                :disabled="positionLineDisabled"
              >
                <span slot="noResult">ไม่มีข้อมูล</span>
                <span slot="noOptions">ไม่มีข้อมูล</span>
              </multiselect>
            </div>
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-2">
        <button type="button" class="btn btn-primary-v mr-3" @click="getList">
          <b-icon icon="search" class="mr-2"></b-icon>
          ค้นหา</button
        ><button type="button" class="btn btn-warning-v" @click="clear">
          ล้างเงื่อนไข
        </button>
      </div>

      <div class="mt-4">
        <custom-table
          :total="total"
          :currentPage.sync="currentPage"
          :perPage.sync="perPage"
        >
          <template slot="table">
            <b-table
              class="table-custom table-custom-helper-person"
              hover
              striped
              :items="items"
              :fields="fields"
              show-empty
              :busy.sync="isBusy"
              @row-clicked="onRowClicked"
            >
              <template v-slot:empty="">
                <div class="text-center fontsize-h4 my-4">ไม่มีรายการ</div>
              </template>

              <div slot="table-busy" class="text-center font-blueinfo my-2">
                <b-spinner class="align-middle"></b-spinner>
                <strong class="pl-2">กำลังโหลด...</strong>
              </div>

              <template #head(fullName)="data">
                <div class="">{{ data.label }}</div>
              </template>

              <template #head(position)="data">
                <div class="">{{ data.label }}</div>
                <div class="fontsize-xs">(ประเภท-ระดับ)</div>
              </template>
              <template #cell(fullName)="data">
                <div>{{ data.item.fullName || "-" }}</div>
              </template>
              <template #cell(position)="data">
                <div class="font-primary">
                  {{ data.item.positionName || "-" }}
                </div>
                <div class="fontsize-xs font-grayviolet">
                  {{ data.item.positionLevelName || "-" }}
                </div>
              </template>
              <template #cell(organize)="data">
                <div class="fontsize-xs font-grayviolet">
                  {{ data.item.orgParentsName || "-" }}
                </div>
                <div>{{ data.item.orgName || "-" }}</div>
              </template>
            </b-table>
          </template>
          <template slot="pagination">
            <b-pagination
              v-show="perPage !== null"
              v-model="currentPage"
              :total-rows="total"
              :per-page="perPage"
              align="fill"
              size="sm"
              class="my-0 my-pagination"
              @change="changePage($event)"
            ></b-pagination>
          </template>
        </custom-table>
      </div>
    </div>
  </b-modal>
</template>
<script>
import { FilterService, SelectorService } from "@/services/main.service";
export default {
  data() {
    return {
      total: 0,
      perPage: 50,
      currentPage: 1,
      isBusy: false,
      fields: [
        {
          key: "positionNumber",
          label: "เลขที่ตำแหน่ง",
          sortable: false,
          class: "text-center",
        },
        {
          key: "fullName",
          label: "ชื่อ-นามสกุล",
          sortable: false,
        },
        {
          key: "position",
          label: "ชื่อตำแหน่งในสายงาน",
          sortable: false,
        },
        {
          key: "personalTypeName",
          label: "สังกัดบุคลากร",
          sortable: false,
        },
        {
          key: "organize",
          label: "ชื่อส่วนราชการ",
          sortable: false,
        },
      ],
      items: [],
      personName: null,
      orgarnizeSelected: null,
      orgarnizeOptions: [],
      personalTypeSelected: null,
      personalTypeOptions: [],
      positionTypeSelected: null,
      positionTypeOptions: [],
      positionLevelSelected: null,
      positionLevelOptions: [],
      positionLineSelected: null,
      positionLineOptions: [],

      personalTypeDisabled: false,
      positionTypeDisabled: true,
      positionLevelDisabled: true,
      positionLineDisabled: true,
    };
  },
  // mounted() {

  // },
  methods: {
    async getOrgarnize() {
      await FilterService.getOrganizeFilter().then((res) => {
        this.orgarnizeOptions = res || [];
        this.orgarnizeSelected = null;
      });
    },
    async getPersonType() {
      await FilterService.getPersonTypeFilter().then((res) => {
        this.personalTypeOptions = res || [];
        this.personalTypeSelected = null;
      });
    },
    async getPositionType(id) {
      this.positionTypeSelected = null;

      await FilterService.getPositionTypeFilter(id)
        .then((res) => {
          this.positionTypeOptions = res.map((m) => {
            return { ...m };
          });
        })
        .catch((err) => {
          this.positionTypeOptions = [];
        });
    },
    async getPositionLevel(id) {
      this.positionLevelSelected = null;

      await FilterService.getPositionLevelFilter(id)
        .then((res) => {
          this.positionLevelOptions = res.map((m) => {
            return { ...m };
          });
        })
        .catch((err) => {
          this.positionLevelOptions = [];
        });
    },

    async getPositionLine(id) {
      this.positionLineSelected = null;

      await FilterService.getPositionLineFilter(id)
        .then((res) => {
          this.positionLineOptions = res.map((m) => {
            return { ...m };
          });
        })
        .catch((err) => {
          this.positionLineOptions = [];
        });
    },
    async clear() {
      this.personName = null;
      this.orgarnizeSelected = null;
      this.personalTypeSelected = null;
      this.positionTypeSelected = null;
      this.positionLevelSelected = null;
      this.positionLineSelected = null;

      this.clearSeletor();

      this.getList();
    },
    async getList() {
      this.isBusy = true;
      const params = {
        orgId: this.orgarnizeSelected?.id || null,
        fullName: this.personName || null,
        personalTypeId: this.personalTypeSelected?.id || null,
        positionTypeId: this.positionTypeSelected?.id || null,
        positionLevelId: this.positionLevelSelected?.id || null,
        positionLineId: this.positionLineSelected?.id || null,
        page: this.currentPage || null,
        size: this.perPage,
      };
      await SelectorService.getSearch(params)
        .then((res) => {
          if (res.status) {
            this.items = res.data;
            this.total = res.total || 0;
          } else {
            this.items = [];
            this.total = 0;
          }
        })
        .catch((err) => {
          this.items = [];
          this.total = 0;
        });

      this.isBusy = false;
    },
    async changePage(event) {
      this.currentPage = event;

      this.getList();
    },
    async show(data) {
      this.getOrgarnize();
      this.getPersonType();
      // this.getPositionType();
      // this.getPositionLevel();
      // this.getPositionLine();

      this.$refs["my-helper-person"].show();

      await this.getList();
    },
    hide() {
      this.$refs["my-helper-person"].hide();
    },
    onRowClicked(item) {
      this.$emit("personSelected", item);
      this.hide();
    },
    clearSeletor() {
      this.personalTypeSelected = null;

      this.positionTypeOptions = [];
      this.positionLevelOptions = [];
      this.positionLineOptions = [];

      this.positionTypeSelected = null;
      this.positionLevelSelected = null;
      this.positionLineSelected = null;

      this.positionTypeDisabled = true;
      this.positionLevelDisabled = true;
      this.positionLineDisabled = true;
    },
    async changePersonType(event) {
      this.positionLevelOptions = [];
      this.positionLevelSelected = null;
      this.positionLineOptions = [];
      this.positionLineSelected = null;

      this.positionTypeDisabled = false;
      this.positionLevelDisabled = true;
      this.positionLineDisabled = true;

      this.getPositionType(event?.id);
    },
    async changePositionType(event) {
      this.positionLevelDisabled = false;
      this.positionLineDisabled = false;

      this.getPositionLevel(event?.id);

      this.getPositionLine(event?.id);
    },
  },
  watch: {
    perPage: function (val) {
      this.getList();
    },
  },
};
</script>
