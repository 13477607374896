import {
  getLinkFileUrl,
  getLinkFileTemplateUrl,
  downloadUrl,
  getAccessToken,
} from "./main-utils.js";
import { objToQueryStr } from "./convert-utils.js";

const downloadFile = async (blob, filename, mimeType) => {
  const download = require("downloadjs");
  download(blob, filename, mimeType);
};

const downloadFileByLink = async (path) => {
  const link = document.createElement("a");
  try {
    link.href = path; //"https://api.airports-hr.piesoft.net/api/files/getfile/288";
    link.click();
    document.close();
    return true;
  } catch (err) {
    console.log(err);
    document.close();
    return false;
  }
};

const downloadFileByFileId = async (id) => {
  const link = document.createElement("a");
  try {
    let url = getLinkFileUrl(id);
    const token = getAccessToken();

    link.href = token ? url + "?access_token=" + token : url; //"https://api.airports-hr.piesoft.net/api/files/getfile/288";
    link.target = "_blank";

    link.click();
    document.close();
    return true;
  } catch (err) {
    console.log(err);
    document.close();
    return false;
  }
};

const downloadFileTemplate = async (id) => {
  const link = document.createElement("a");
  try {
    let url = getLinkFileTemplateUrl(id);
    const token = getAccessToken();

    link.href = token ? url + "&access_token=" + token : url;
    link.target = "_blank";

    link.click();
    document.close();
    return true;
  } catch (err) {
    console.log(err);
    document.close();
    return false;
  }
};

const downloadFileExport = async (str, objParam) => {
  const link = document.createElement("a");

  try {
    const params = {
      ...objParam,
    };

    const token = getAccessToken();
    if (token) {
      params["access_token"] = token;
    }

    const queryStr = "?" + objToQueryStr(params);
    link.href = downloadUrl(str) + queryStr;
    link.target = "_blank";

    link.click();
    document.close();
    return true;
  } catch (err) {
    console.log(err);
    document.close();
    return false;
  }
};

export default {
  downloadFile,
  downloadFileByLink,
  downloadFileByFileId,
  downloadFileTemplate,
  downloadFileExport,
};

export { downloadFileExport };
