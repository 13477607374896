import { downloadFileExport } from "@/utils/file-utils";
import httpClient from "../httpClient";

const END_POINT = "training";

class TrainingService {
  async getTraining(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/findTraining`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getById(id) {
    const params = {
      id: id,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/getById`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response.data;
    } else {
      return response || {};
    }
  }

  async postAddTraining(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/addTraining`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || {};
    }
  }

  async postEditTraining(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/editTraining`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || {};
    }
  }

  async postDeleteTraining(id) {
    const params = {
      id: id,
    };
    const config = {
      method: "post",
      url: `${END_POINT}/deleteTraining`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || {};
    }
  }

  async getSummaryTraining(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/summaryTraining`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getSummaryTrainingTypeActivity(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/summaryTrainingTypeActivity`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getTrainingReport(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/trainingReport`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async updateTrainingDocumentExternalLink(params, data) {
    const config = {
      method: "post",
      url: `${END_POINT}/updateTrainingDocumentExternalLink`,
      params: params,
      data: data,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async updateTrainingCertificateCreateDate(data) {
    const config = {
      method: "post",
      url: `${END_POINT}/updateTrainingCertificateCreateDate`,
      data: data,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async exportTraining(params) {
    downloadFileExport(`${END_POINT}/exportTraining`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListTraining`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // return response || {};
  }

  async exportListTraining(params) {
    downloadFileExport(`${END_POINT}/exportListTraining`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListTraining`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // return response || {};
  }

  async exportListTrainingReport(params) {
    downloadFileExport(`${END_POINT}/exportListTrainingReport`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListTrainingReport`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // return response || {};
  }

  async exportListTrainingYear(params) {
    downloadFileExport(`${END_POINT}/exportListTrainingYear`, params);

    return { status: true };
    // const config = {
    //   method: "get",
    //   url: `${END_POINT}/exportListTrainingYear`,
    //   params: params,
    //   responseType: "blob",
    // };

    // const response = await httpClient(config);

    // return response || {};
  }
}

export default new TrainingService();
