const menuList = [
  {
    menuId: 1,
    parentId: 0,
    title: "จัดการข้อมูลสวัสดิการ",
    titleCollapse: "จัดการข้อมูลสวัสดิการ",
    // iconClass: ["fas", "home"],
    iconClass: require("@/assets/images/welfare.png"),
    auth: ["auth_request"],
    hasSub: true,
    role: 3,
  },
  {
    menuId: 2,
    parentId: 1,
    title: "นำเข้าการรับสวัสดิการ",
    titleCollapse: "นำเข้าการรับสวัสดิการ",
    to: "ImportWelfare",
    auth: ["auth_requesthis"],
  },
  {
    menuId: 3,
    parentId: 1,
    title: "รายงานการรับสวัสดิการ",
    titleCollapse: "รายงานการรับสวัสดิการ",
    to: "ReportWelfare",
    auth: ["auth_training"],
  },
  {
    menuId: 4,
    parentId: 1,
    title: "ตั้งค่าประเภทสวัสดิการ",
    titleCollapse: "ตั้งค่าประเภทสวัสดิการ",
    to: "SettingWelfare",
    auth: ["auth_report"],
  },
  {
    menuId: 5,
    parentId: 0,
    title: "ระบบพัฒนาบุคลากร",
    titleCollapse: "ระบบพัฒนาบุคลากร",
    iconClass: require("@/assets/images/certificate.png"),
    auth: ["auth_request"],
    hasSub: true,
    role: 2,
  },
  {
    menuId: 6,
    parentId: 5,
    title: "รายการหลักสูตร",
    titleCollapse: "รายการหลักสูตร",
    to: "CourseList",
    auth: ["auth_requesthis"],
  },
  {
    menuId: 7,
    parentId: 5,
    title: "จัดการรายชื่อและรายงานผล",
    titleCollapse: "จัดการรายชื่อและรายงานผล",
    to: "ReportTrainingManage",
    auth: ["auth_training"],
  },
  {
    menuId: 9,
    parentId: 5,
    title: "รายงานการพัฒนาบุคลากร",
    titleCollapse: "รายงานการพัฒนาบุคลากร",
    to: "ReportDevelopment",
    auth: ["auth_report"],
  },
  {
    menuId: 8,
    parentId: 5,
    title: "ข้อมูลทุนศึกษาและทุนอื่นๆ",
    titleCollapse: "ข้อมูลทุนศึกษาและทุนอื่นๆ",
    to: "ScholarshipList",
    auth: ["auth_report"],
  },
  {
    menuId: 10,
    parentId: 0,
    title: "จัดการข้อมูลเครื่องราชฯ",
    titleCollapse: "จัดการข้อมูลเครื่องราชฯ",
    iconClass: require("@/assets/images/award.png"),
    auth: ["auth_request"],
    hasSub: true,
    role: 1,
  },
  {
    menuId: 11,
    parentId: 10,
    title: "รายชื่อผู้มีสิทธิ์ได้รับเครื่องราชฯ",
    titleCollapse: "รายชื่อผู้มีสิทธิ์ได้รับเครื่องราชฯ",
    to: "InsListPosition",
    auth: ["auth_requesthis"],
  },
  {
    menuId: 12,
    parentId: 10,
    title: "จำนวนเครื่องราชฯ เฉพาะบุคคล",
    titleCollapse: "จำนวนเครื่องราชฯ เฉพาะบุคคล",
    to: "InsManagePerson",
    auth: ["auth_training"],
  },
  {
    menuId: 13,
    parentId: 10,
    title: "รับ-คืนเครื่องราชฯ เฉพาะบุคคล",
    titleCollapse: "รับ-คืนเครื่องราชฯ เฉพาะบุคคล",
    to: "InsHistoryPerson",
    auth: ["auth_report"],
  },
  {
    menuId: 14,
    parentId: 10,
    title: "จำนวนเครื่องราชฯ ส่วนกลาง",
    titleCollapse: "จำนวนเครื่องราชฯ ส่วนกลาง",
    to: "InsManageOfficer",
    auth: ["auth_report"],
  },
  {
    menuId: 15,
    parentId: 10,
    title: "ยืม-คืนเครื่องราชฯ ส่วนกลาง",
    titleCollapse: "ยืม-คืนเครื่องราชฯ ส่วนกลาง",
    to: "InsHistoryOfficer",
    auth: ["auth_report"],
  },
  {
    menuId: 16,
    parentId: 0,
    title: "จัดการผู้ใช้งาน",
    titleCollapse: "จัดการผู้ใช้งาน",
    iconClass: require("@/assets/images/management.png"),
    auth: ["auth_request"],
    hasSub: true,
    role: 4,
  },
  {
    menuId: 18,
    parentId: 16,
    title: "จัดการรายชื่อผู้ใช้งาน",
    titleCollapse: "จัดการรายชื่อผู้ใช้งาน",
    to: "UserList",
    auth: ["auth_users"],
  },
  {
    menuId: 19,
    parentId: 16,
    title: "เรียกดูประวัติผู้ใช้งาน",
    titleCollapse: "เรียกดูประวัติผู้ใช้งาน",
    to: "UserHistory",
    auth: ["auth_users"],
  },
];

const breadcrumbs = [
  {
    title: "จัดการข้อมูลสวัสดิการ",
    iconClass: require("@/assets/images/welfare.png"),
    children: [
      {
        to: "ImportWelfare",
        title: "นำเข้าการรับสวัสดิการ",
        children: [
          {
            to: "FormWelfare",
          },
        ],
      },
      {
        to: "ReportWelfare",
        title: "รายงานการรับสวัสดิการ",
      },
      {
        to: "SettingWelfare",
        title: "ตั้งค่าประเภทสวัสดิการ",
      },
    ],
  },
  {
    title: "ระบบพัฒนาบุคลากร",
    iconClass: require("@/assets/images/certificate.png"),
    children: [
      {
        to: "CourseList",
        title: "รายการหลักสูตร",
        children: [
          {
            to: "CourseForm",
          },
        ],
      },
      {
        to: "ReportTrainingManage",
        title: "จัดการรายชื่อและรายงานผล",
        children: [
          {
            to: "ReportTrainingDetail",
            // title: "เพิ่มรายชื่ผู้ลงทะเบียน",
            // children: [
            //   {
            //     to: "TrainingRegisForm",
            //   },
            // ],
          },
          {
            to: "TrainingRegisForm",
          },
        ],
      },
      {
        to: "ScholarshipList",
        title: "ข้อมูลทุนศึกษาและทุนอื่นๆ",
        children: [
          {
            to: "ScholarshipForm",
          },
        ],
      },
      {
        to: "ReportDevelopment",
        title: "รายงานการพัฒนาบุคลากร",
      },
    ],
  },
  {
    title: "จัดการข้อมูลเครื่องราชฯ",
    iconClass: require("@/assets/images/award.png"),
    children: [
      {
        to: "InsListPosition",
        title: "รายชื่อผู้มีสิทธิ์ได้รับเครื่องราชฯ",
      },
      {
        to: "InsListPositionPerson",
        title: "รายชื่อผู้มีสิทธิ์ได้รับเครื่องราชฯ",
        children: [
          {
            to: "InsPersonalManage",
            title: "จัดการข้อมูล",
          },
          {
            to: "InsPersonAdd",
            title: "เพิ่มข้อมูล",
          },
        ],
      },
      {
        to: "InsPersonalSummary",
        title: "บัญชีแสดงจำนวนชั้นตราเครื่องราชฯและคุณสมบัติ",
      },
      {
        to: "InsManagePerson",
        title: "จำนวนเครื่องราชฯ เฉพาะบุคคล",
      },
      {
        to: "InsManagePersonList",
        title: "จำนวนเครื่องราชฯ เฉพาะบุคคล",
        children: [
          {
            to: "InsManagePersonForm",
          },
        ],
      },
      {
        to: "InsHistoryPerson",
        title: "ประวัติการรับ-คืนเครื่องราชฯ เฉพาะบุคคล",
        children: [
          {
            to: "ManageForm1",
            title: "จัดการข้อมูลผู้รับเครื่องราชฯ แบบเฉพาะบุคคล",
          },
          {
            to: "ManageForm2",
            title: "จัดการข้อมูลผู้รับเครื่องราชฯ แบบเฉพาะบุคคล",
          },
          {
            to: "ManageForm3",
            title: "จัดการข้อมูลผู้รับเครื่องราชฯ แบบเฉพาะบุคคล",
          },
        ],
      },
      {
        to: "InsManageOfficer",
        title: "จำนวนเครื่องราชฯ ส่วนกลาง",
        children: [
          {
            to: "InsManageOfficerDecoration",
            title: "จัดการจำนวนเครื่องราชฯ แบบส่วนกลาง",
          },
        ],
      },
      {
        to: "InsHistoryOfficer",
        title: "ยืม-คืนเครื่องราชฯ ส่วนกลาง",
        children: [
          {
            to: "InsHistoryOfficerForm",
            title: "เพิ่มคำขอยืมเครื่องราชฯ",
          },
          {
            to: "InsHistoryOfficerManage",
            title: "จัดการคำขอยืมเครื่องราชฯ",
          },
          {
            to: "InsHistoryOfficerManage2",
            title: "จัดการคำขอยืมเครื่องราชฯ",
          },
          {
            to: "InsHistoryOfficerManage3",
            title: "จัดการคำขอยืมเครื่องราชฯ",
          },
          {
            to: "InsHistoryOfficerManage4",
            title: "จัดการคำขอยืมเครื่องราชฯ",
          },
          {
            to: "InsHistoryOfficerManage5",
            title: "จัดการคำขอยืมเครื่องราชฯ",
          },
        ],
      },
    ],
  },
  {
    title: "จัดการผู้ใช้งาน",
    iconClass: require("@/assets/images/management.png"),
    children: [
      {
        to: "UserList",
        title: "จัดการรายชื่อผู้ใช้งาน",
        children: [
          {
            to: "UserListForm",
          },
        ],
      },
      {
        to: "UserHistory",
        title: "เรียกดูประวัติผู้ใช้งาน",
      },
    ],
  },
];

const state = {
  menus: [...menuList],
  breadcrumbs: [...breadcrumbs],
};

export const menus = {
  namespaced: true,
  state,
};
