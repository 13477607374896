<template>
  <div id="AdminHeader">
    <div class="d-flex align-items-center pr-3 admin-header shadow-sm">
      <div
        class="flex-fill d-flex pr-3 fontsize-h3 align-items-center weight-600"
      >
        <div class="header-logo mr-3">
          <img src="@/assets/logo.png" />
        </div>
        <div>
          <div class="fontsize-h5 mt-1">
            ระบบเพิ่มประสิทธิภาพการบริหารทรัพยากรบุคคล
          </div>

          <div class="fontsize-sm weight-400">
            <b-icon icon="dot" class="mr-1" scale="2"></b-icon>กรมท่าอากาศยาน
            <b-icon icon="dot" class="ml-1" scale="2"></b-icon>
          </div>
        </div>
      </div>
      <b-navbar-nav class="mx-2 nav-logout">
        <b-nav-item-dropdown right class="nav-dropdown-logout">
          <template #button-content>
            <div class="d-flex flex-row align-items-center">
              <b-avatar
                class="admin-header-avatar"
                :src="userImage"
                onerror="javascript:this.src='/images/default-profile.png'"
              ></b-avatar>

              <div class="d-flex flex-column flex-1">
                <span
                  class="ml-3 mr-2 admin-header-user"
                  style="display: inline-block; min-width: 100px"
                  >{{ usernameLogin }}</span
                >
                <span
                  class="ml-3 mr-2 admin-header-position"
                  style="display: inline-block; min-width: 100px"
                  >{{ positionLogin }}</span
                >
              </div>
            </div>
          </template>
          <b-dropdown-item href="#" @click="getLogout"
            ><font-awesome-icon
              class="mr-2 font-danger"
              :icon="['fas', 'power-off']"
            />
            ออกจากระบบ</b-dropdown-item
          >
        </b-nav-item-dropdown>
      </b-navbar-nav>
    </div>
  </div>
</template>

<script>
export default {
  name: "admin-header",
  data() {
    return {
      userImage: null,
    };
  },
  methods: {
    getLogout() {
      this.alertConfirm("คุณต้องการออกจากระบบใช่หรือไม่").then((res) => {
        if (res) {
          this.$store
            .dispatch("account/logout")
            .then(
              (res) => {},
              (error) => {
                console.log("getLogout():error", error);
              }
            )
            .finally(() => {
              this.$router.push({ name: "Login" });
            });
        }
      });
    },
  },
  computed: {
    usernameLogin: function () {
      const detail = this.$utils.main.getUserInfo() || {};
      return detail?.fullName || "-";
    },
    positionLogin: function () {
      const detail = this.$utils.main.getUserInfo() || {};
      return detail?.organizeName || "-";
    },
  },
};
</script>

<style lang="scss"></style>
