<template>
  <div>
    <div class="mb-3 d-flex align-items-center justify-content-between">
      <slot name="page-size">
        <div class="d-flex align-items-center flex-fill">
          <multiselect-size
            v-model="perPage2"
            :options="listNumRows"
            v-show="!hiddenSize"
            class="select-greenmint px-2"
          >
          </multiselect-size>

          <div class="font-subtitle" :class="{ 'ml-3': !hiddenSize }">
            {{ textNumRow }}
          </div>
        </div>
      </slot>

      <slot name="other"></slot>
    </div>
    <div class="table-responsive">
      <slot name="table"></slot>
    </div>

    <slot name="pagination"></slot>
  </div>
</template>

<script>
export default {
  name: "custom-table",
  props: {
    currentPage: {
      type: Number,
      default: 1,
    },
    total: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
      default: 50,
    },
    hiddenSize: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      listNumRows: [
        { id: 25, text: 25 },
        { id: 50, text: 50 },
        { id: 100, text: 100 },
        { id: null, text: "ทั้งหมด" },
      ],
      perPage2: { id: 50, text: 50 },
      currentPage2: 1,
    };
  },
  watch: {
    perPage: {
      handler: function (val) {
        this.perPage2 = this.listNumRows.find((f) => f.id == val);
      },
      deep: true,
    },
    currentPage: {
      handler: function (val) {
        this.currentPage2 = val;
      },
      deep: true,
    },
    perPage2: {
      handler: function (val) {
        this.$emit("update:perPage", val?.id);
        this.$emit("update:currentPage", 1);
      },
      deep: true,
    },
  },
  computed: {
    textNumRow() {
      const tt = this.$utils.convert.toCommas(this.total || 0);

      if (this.perPage2?.id === null || this.hiddenSize) {
        return `แสดงทั้งหมด จากทั้งหมด ${tt} รายการ`;
      } else {
        const num1 = (this.currentPage2 - 1) * this.perPage2?.id + 1;
        const num2 =
          (this.currentPage2 - 1) * this.perPage2?.id + this.perPage2?.id;

        return `แสดง  ${
          this.total > 0 ? this.$utils.convert.toCommas(num1 || 0) : 0
        } ถึง
          ${
            num2 > this.total ? tt : this.$utils.convert.toCommas(num2 || 0)
          } จากทั้งหมด
          ${tt} รายการ`;
      }
    },
  },
};
</script>

<style></style>
