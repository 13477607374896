import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Multiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
import { BootstrapVue, IconsPlugin } from "bootstrap-vue";

/* import the fontawesome core */
import { library } from "@fortawesome/fontawesome-svg-core";

/* import specific icons */
import { fas } from "@fortawesome/free-solid-svg-icons";
import { far } from "@fortawesome/free-regular-svg-icons";

import PerfectScrollbar from "vue2-perfect-scrollbar";
import "vue2-perfect-scrollbar/dist/vue2-perfect-scrollbar.css";
import Vuelidate from "vuelidate";
import ComponentPlugin from "@/components/cores/_ComponentPlugin";

import mainUtils from "@/utils/main-utils";
import convertUtils from "@/utils/convert-utils";

import VueSweetalert2 from "vue-sweetalert2";
import "sweetalert2/dist/sweetalert2.min.css";

import vuetify from "@/plugins/vuetify";

import VueGoodTablePlugin from "vue-good-table";
import "vue-good-table/dist/vue-good-table.css";

/* import font awesome icon component */
import {
  FontAwesomeIcon,
  FontAwesomeLayers,
} from "@fortawesome/vue-fontawesome";

import ModalHelperPerson from "@/views/Modal/ModalHelperPerson.vue";
import fileUtils from "./utils/file-utils";

// register directive v-number and component <number>
import number from "@coders-tm/vue-number-format";

Vue.component("modal-helper-person", ModalHelperPerson);
/**
 * Multiselect component,mixin
 */
Vue.component("multiselect", {
  mixins: [Multiselect],
  props: {
    label: {
      default: "text",
    },
    selectLabel: {
      default: "",
    },
    deselectLabel: {
      default: "X",
    },
    selectedLabel: {
      default: "",
    },
    showLabels: {
      default: true,
    },
    closeOnSelect: {
      default: true,
    },
    searchable: {
      default: true,
    },
    placeholder: {
      default: "",
    },
    // showNoOptions: {
    //   default: false,
    // },
    // showNoResults: {
    //   default: false,
    // },
  },
  // slots: {
  // },
});
Vue.component("multiselect-size", {
  mixins: [Multiselect],
  props: {
    label: {
      default: "text",
    },
    selectLabel: {
      default: "",
    },
    deselectLabel: {
      default: "",
    },
    selectedLabel: {
      default: "",
    },
    showLabels: {
      default: false,
    },
    closeOnSelect: {
      default: true,
    },
    searchable: {
      default: false,
    },
    allowEmpty: {
      default: false,
    },
    placeholder: {
      default: "",
    },
  },
});

// Make BootstrapVue available throughout your project
Vue.use(BootstrapVue);
// Optionally install the BootstrapVue icon components plugin
Vue.use(IconsPlugin);

Vue.use(PerfectScrollbar);
Vue.use(Vuelidate);
Vue.use(ComponentPlugin);
Vue.use(VueSweetalert2);
Vue.use(VueGoodTablePlugin);
Vue.use(number, {
  precision: 2,
  decimal: ".",
  separator: ",",
  prefix: "",
  masked: false,
  nullValue: null,
});

library.add(fas);
library.add(far);

/* add font awesome icon component */
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.component("font-awesome-layers", FontAwesomeLayers);

Vue.prototype.$utils = {
  main: { ...mainUtils },
  convert: { ...convertUtils },
  file: { ...fileUtils },
};

Vue.config.productionTip = false;
new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");

Vue.mixin({
  filters: { ...convertUtils, getLinkFileUrl: mainUtils.getLinkFileUrl },
  methods: {
    alertSuccess(title, text) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-md">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-md">ทำรายการสำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col fontsize-h5 weight-600 font-primary">
              ${_title.join("")}
            </div>
            <div ${_text.length == 0 ? "class='d-none'" : ""}>${_text.join(
              ""
            )}</div>`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "ตกลง",
            customClass: {
              confirmButton: "btn btn-primary-v",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "success",
            // timer: timer,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertConfirm(
      title,
      text,
      confirmButtonText = "ยืนยัน",
      cancelButtonText = "ยกเลิก",
      timer = 2000
    ) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-md">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-md">ยืนยัน</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col fontsize-h5 weight-600 font-primary">
              ${_title.join("")}
            </div>
            <div  ${_text.length == 0 ? "class='d-none'" : ""}>${_text.join(
              ""
            )}</div>`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            customClass: {
              confirmButton: "btn btn-primary-v mr-5",
              cancelButton: "btn btn-secondary-v",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            // icon: "question",
            reverseButtons: false,
            //timer: timer,
          })
          .then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    },
    alertDownload(
      title,
      text,
      confirmButtonText = "ยืนยัน",
      cancelButtonText = "ยกเลิก",
      timer = 2000
    ) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-md">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-md">ยืนยัน</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col fontsize-h5 weight-600 font-primary">
              ${_title.join("")}
            </div>
            <div  ${_text.length == 0 ? "class='d-none'" : ""}>${_text.join(
              ""
            )}</div>`,
            showCancelButton: true,
            showConfirmButton: true,
            confirmButtonText: confirmButtonText,
            cancelButtonText: cancelButtonText,
            customClass: {
              confirmButton: "btn btn-primary-v mr-3",
              cancelButton: "btn btn-secondary-v",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "success",
            reverseButtons: false,
            //timer: timer,
          })
          .then((result) => {
            if (result.isConfirmed) {
              resolve(true);
            } else {
              resolve(false);
            }
          });
      });
    },
    alertFail(title, text) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-md">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-md">ทำรายการไม่สำเร็จ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col fontsize-h5 weight-600 font-primary">
              ${_title.join("")}
            </div>
            <div ${_text.length == 0 ? "class='d-none'" : ""}>${_text.join(
              ""
            )}</div>`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "ตกลง",
            customClass: {
              confirmButton: "btn btn-primary-v",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "error",
            // timer: timer,
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertLoading(title, text, timer = 3000) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-h3">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-h3">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-h3">อยู่ระหว่างดำเนินการ</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `
            <div class="d-flex justify-content-center mb-3 mt-3">
              <div class="loader"></div>
            </div>
            <div class="d-col">
              ${_title.join("")}
              ${_text.join("")}
            </div>
            `,
            showCancelButton: false,
            showConfirmButton: false,
            buttonsStyling: false,
            allowOutsideClick: false,
            // timer: timer,
            //icon: "question",
          })
          .then(() => {
            resolve(true);
          });
      });
    },
    alertInfomation(title, text) {
      let _title = [];
      if (title && title.length > 0 && typeof title === "object") {
        _title = title.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (title) {
        _title.push(`<div class="fontsize-md">${title}</div>`);
      } else {
        _title.push(`<div class="fontsize-md">ยืนยัน</div>`);
      }

      let _text = [];
      if (text && text.length > 0 && typeof text === "object") {
        _text = text.map((m) => {
          return `<div class="fontsize-md">${m}</div>`;
        });
      } else if (text) {
        _text.push(`<div class="fontsize-md">${text}</div>`);
      }

      return new Promise((resolve, reject) => {
        this.$swal
          .fire({
            html: `<div class="d-col fontsize-h5 weight-600 font-primary">
              ${_title.join("")}
            </div>
            <div  ${_text.length == 0 ? "class='d-none'" : ""}>${_text.join(
              ""
            )}</div>`,
            showCancelButton: false,
            showConfirmButton: true,
            confirmButtonText: "ตกลง",
            customClass: {
              confirmButton: "btn btn-primary-v",
            },
            buttonsStyling: false,
            allowOutsideClick: false,
            icon: "info",
            reverseButtons: false,
          })
          .then((result) => {
            resolve(true);
          });
      });
    },
  },
});
