<template>
  <div
    id="AppLayout"
    :class="[isMenuCollapse ? 'menu-collapse' : 'menu-expand']"
  >
    <app-header></app-header>

    <app-sidebar></app-sidebar>

    <main class="content-wrapper-admin">
      <router-view></router-view>
    </main>

    <!-- <app-footer></app-footer> -->
  </div>
</template>

<script>
import AppHeader from "./Header.vue";
import AppSidebar from "./Sidebar.vue";
import AppFooter from "./Footer.vue";

export default {
  name: "app-layout",
  components: { AppHeader, AppSidebar, AppFooter },
  data() {
    return {
      isMenuCollapse: false,
    };
  },
};
</script>

<style></style>
