import httpClient from "../httpClient";
import { downloadFileExport } from "@/utils/file-utils";

const END_POINT = "userManagement";

class UserManageService {
  async getSearchUser(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/searchUser`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async postDeleteUser(id) {
    const params = {
      userId: id,
    };
    const config = {
      method: "post",
      url: `${END_POINT}/deleteUser`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getCheckHaveUser(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/checkHaveUser`,
      params: params,
    };

    const response = await httpClient(config);

    if (response) {
      return response.data;
    } else {
      return response || [];
    }
  }

  async postAddUser(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/addUser`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getUserById(id) {
    const params = {
      userId: id,
    };
    const config = {
      method: "get",
      url: `${END_POINT}/getUserById`,
      params: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async postEditUser(params) {
    const config = {
      method: "post",
      url: `${END_POINT}/editUser`,
      data: params,
    };

    const response = await httpClient(config);

    if (response && response.data) {
      return response;
    } else {
      return response || [];
    }
  }

  async getSearchUsage(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/searchUsage`,
      params: params,
    };

    const response = await httpClient(config);

    if (response) {
      return response;
    } else {
      return response || [];
    }
  }

  async getExportExcel(params) {
    downloadFileExport(`${END_POINT}/exportUsage`, params);

    return { status: true };
  }
}

export default new UserManageService();
