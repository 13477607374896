var render = function render(){var _vm=this,_c=_vm._self._c;return _c('aside',{staticClass:"admin-sidebar shadow-sm",attrs:{"id":"AdminSidebar"}},[_c('perfect-scrollbar',{staticClass:"sidebar-perfect-scrollbar"},[_c('nav',[_c('ul',[_vm._l((_vm.menus),function(menu,index){return [(menu.parentId == 0 && menu.hasSub)?[_c('li',{key:index,staticClass:"li-menu parent-level",class:{
                  'active-submenu': menu.active || menu.expand,
                  active: menu.active || menu.expand,
                }},[_c('div',{staticClass:"menu-name-content"},[(menu.iconClass)?_c('img',{staticClass:"mr-3",attrs:{"src":menu.iconClass}}):_vm._e(),_c('span',[_vm._v(_vm._s(menu.title))]),_c('span',{staticClass:"title-collapse"},[_vm._v(_vm._s(menu.titleCollapse))])])])]:(menu.parentId == 0)?[_c('li',{key:index,staticClass:"li-menu parent-level-click",class:{
                  'active-submenu': menu.active || menu.expand,
                  active: menu.active || menu.expand,
                }},[_c('router-link',{staticClass:"w-100",attrs:{"to":{ name: menu.to }}},[_c('div',{staticClass:"menu-name-content"},[(menu.iconClass)?_c('img',{staticClass:"mr-3",attrs:{"src":menu.iconClass}}):_vm._e(),_c('span',{},[_vm._v(_vm._s(menu.title))]),_c('span',{staticClass:"title-collapse"},[_vm._v(_vm._s(menu.titleCollapse))])])])],1)]:[_c('li',{key:index,staticClass:"li-menu sub-level",class:{
                  'active-submenu': menu.active || menu.expand,
                  active: menu.active || menu.expand,
                }},[_c('router-link',{staticClass:"w-100",attrs:{"to":{ name: menu.to }}},[_c('div',{staticClass:"menu-name-content"},[(menu.iconClass)?_c('img',{staticClass:"mr-3",attrs:{"src":menu.iconClass}}):_vm._e(),_c('span',{staticClass:"pl-4"},[_c('font-awesome-icon',{staticClass:"mr-2 align-middle",staticStyle:{"font-size":"5px"},attrs:{"icon":"fa-solid fa-circle"}}),_vm._v(_vm._s(menu.title))],1),_c('span',{staticClass:"title-collapse"},[_vm._v(_vm._s(menu.titleCollapse))])])])],1)]]})],2)]),_c('div',{staticClass:"menu-controller",on:{"click":function($event){return _vm.sidemenuCollapse(false)}}})]),_c('div',{staticClass:"sidemenu-toggle-collapse",on:{"click":function($event){return _vm.sidemenuCollapse(true)}}},[_c('font-awesome-icon',{staticClass:"m-auto",attrs:{"icon":['fas', 'angle-double-left']}})],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }