import httpClient from "../httpClient";
import { downloadFileExport } from "@/utils/file-utils";

const END_POINT = "welfareReport";

class WelfareReportService {
  async getSearch(params) {
    const config = {
      method: "get",
      url: `${END_POINT}/search`,
      params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getListCard() {
    const config = {
      method: "get",
      url: `${END_POINT}/listCard`,
      // params: params,
    };

    const response = await httpClient(config);

    return response || {};
  }

  async getExportExcel(params) {
    downloadFileExport(`${END_POINT}/exportExcel`, params);

    return { status: true };
  }
}

export default new WelfareReportService();
